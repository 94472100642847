import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet generalment sense peu amb el capell en forma de copinya o semblant a un ventall amb petits pèls eriçats. El capell mesura d’1 a 5 cm de diàmetre, de color blanc pur un poc grisenc. Les làmines presenten uns reflexos rosats i típicament bífides. Les espores són blanques en massa, cilíndriques, de 3-4 x 1-1,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      